<template>
  <section :class="$style.container">
    <ButtonLoginSwitch
      v-if="!redirectTo && !loginChallenge"
      :clickHandler="goToUserLogin"
    >
      User Portal Login
    </ButtonLoginSwitch>
    <div :class="$style.formContainer">
      <AdminLoginController
        v-if="isAdminIdFirst"
        data-test-id="AdminLoginController"
        :error="error"
      />
      <AdminLoginForm
        v-else
        :autofocus="autofocus"
        :form="form"
        :updateAutofocus="updateAutofocus"
        :goToUserLogin="goToUserLogin"
        v-bind="$props"
      />
      <LogoIcon />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapStores } from 'pinia';
import { useFeatureFlags } from 'client/stores/FeatureFlag';

import AdminLoginController from 'client/containers/Login/AdminLoginController.vue';
import AdminLoginForm from 'client/containers/Login/AdminLoginForm.vue';
import ButtonLoginSwitch from 'client/components/ButtonLoginSwitch.vue';
import Constants from 'client/util/Constants';
import Cookies from 'js-cookie';
import LoginConsts from 'client/util/Constants/LoginConstants';
import LogoIcon from 'client/components/LogoIcon.vue';
import UserLoginStore from 'client/stores/UserLoginStore';
import loginUtil from 'client/components/mixins/loginUtil';

const { redirectTo } = Constants;
const { clientType, clientTypeCookie } = LoginConsts;

export default {
  name: 'AdminLoginWrapper',

  mixins: [
    loginUtil,
  ],

  props: {
    clientType: {
      type: String,
      default: clientType.admin,
    },
    error: {
      type: String,
      default: undefined,
    },
    form: {
      type: Object,
      default: () => ({
        email: '',
        password: '',
      }),
    },
    redirectTo: {
      type: String,
      default: '',
    },
    requestKey: {
      type: String,
      default: '',
    },
  },

  components: {
    AdminLoginForm,
    AdminLoginController,
    LogoIcon,
    ButtonLoginSwitch,
  },

  methods: {
    goToUserLogin() {
      // removing redirectTo from session to prevent issues when swtiching clientTypes
      sessionStorage.removeItem(redirectTo);

      // TODO NA-487 will investigate how to improve global state sharing
      if (this.isAdminIdFirst) {
        this.vueRouteChange('Login', { form: { email: this.userLoginStore.email, password: this.userLoginStore.password } });
      } else {
        this.vueRouteChange('Login', { form: this.form });
      }
    },
  },

  computed: {
    ...mapStores(UserLoginStore),
    ...mapGetters('LoginOauthAdminFlowModel', [
      'loginChallenge',
    ]),
    // True if org id is available in local storage and 2024-07-01-admin-id-first is enabled
    isAdminIdFirst() {
      const { isAdminIdFirst } = useFeatureFlags();
      return isAdminIdFirst;
    },
  },

  mounted() {
    Cookies.set(clientTypeCookie, 'admin');
  },
};
</script>

<style module>
@import '../../css/login-components.css';
</style>
